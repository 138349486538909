
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class UsedCompleteDialog extends Vue {
  @Prop({ required: true })
  readonly value!: boolean;

  get wrapperDialog() {
    return this.value;
  }

  set wrapperDialog(flag: boolean) {
    this.$emit('input', flag);
  }
}
