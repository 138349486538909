


































































































import { Component, Vue } from 'vue-property-decorator';
import EventList from '@/components/event/EventList.vue';
import EventRegisteredList from '@/components/event/EventRegisteredList.vue';

@Component({
  components: {
    EventList,
    EventRegisteredList
  }
})
export default class P0232 extends Vue {
  // 表示するタブ
  displayTab = '';

  created() {
    this.initialize();
  }

  initialize() {
    this.handleQueryTab();
  }

  // ログイン状態
  get isAuth() {
    return this.$auth.isAuthenticated && !this.$auth.loading;
  }

  /**
   * 画面ロード時のクエリパラメータによって表示するタブを制御する。
   */
  handleQueryTab() {
    const query = this.$route.query || {};
    // nullの場合は【全て表示】タブを表示
    if (!query.tab) {
      this.displayTab = this.$cls.EVENT_TAB_NAME_CLS.TOP.CD;
      return;
    }
    const CD = this.$cls.getCodeByPathName('EVENT_TAB_NAME_CLS', query.tab);
    // EVENT_TAB_NAME_CLSとマッチしてない場合は【全て表示】タブを表示
    if (CD === '') {
      this.displayTab = this.$cls.EVENT_TAB_NAME_CLS.TOP.CD;
    } else {
      this.displayTab = CD;
    }
  }
  // タブクリック動作
  isSelectTab(id: string) {
    // クエリパラメータの値を変更する。
    this.$router
      .push({ query: { tab: this.$cls.getPathName('EVENT_TAB_NAME_CLS', id) } })
      .catch(() => {});
    this.displayTab = id;
  }
}
