






















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { EventRegisteredItem } from '@/models/EventRegisteredItem';
import { EventCmsRepository } from '@/repositories/EventCmsRepository';
import { EventRepository } from '@/repositories/EventRepository';
import { EventEntryItem } from '@/models/EventEntryItem';
import { EventItem } from '@/models/EventItem';
import { ReqPatchUseEventStatus } from '@/gen/api';
import UsedConfirmDialog from '@/components/event/UsedConfirmDialog.vue';
import UsedCompleteDialog from '@/components/event/UsedCompleteDialog.vue';

@Component({
  components: {
    UsedConfirmDialog,
    UsedCompleteDialog
  }
})
export default class EventRegisteredList extends Vue {
  readonly INITIAL_PAGE_NO = 1;
  readonly LIMIT_NUM = 10; // 1度に表示する件数

  // エラーメッセージ
  errorMsg = '';
  // 消込モーダル内のエラーメッセージ
  errorMsgUsedConfirmDialog = '';
  // インフォメッセージ
  infoMsg = '';
  // 総件数
  totalCount: number = 0;
  pageNo: number = this.INITIAL_PAGE_NO;
  // イベント申込履歴情報(APIから取得した情報)
  eventEntryItemList: EventEntryItem[] = [];
  // ページ毎に分割したリスト
  splitPageList: EventEntryItem[][] = [];
  // イベント申込履歴画面表示用リスト(１ページ分)
  eventRegisteredItemList: EventRegisteredItem[] = [];

  // 3行以上で非表示にしているイベント概要の表示フラグ（キーはindexにする）
  isShowMoreText: { [key: string]: boolean } = {};
  //イベント概要表示
  showMoreText(key: string) {
    this.$set(this.isShowMoreText, key, true);
  }

  //イベント概要を非表示
  hiddenMoreText(key: string) {
    this.$set(this.isShowMoreText, key, false);
  }

  //リサイズイベントのフラグ
  isFlagResize = false;

  // 3行以上で非表示にするかどうかのフラグ
  isShowMoreView: { [key: string]: boolean } = {};

  // モーダル表示管理変数
  isUsedConfirmDialogOpening = false;
  isUsedCompleteDialogOpening = false;

  // 消込対象イベント
  usedTargetEvent = {} as EventRegisteredItem;

  // ボタン連打防止のため、非同期処置中であるか管理
  buttonLoading = false;

  // テキストの中身（高さ）によって「もっと見る」矢印を表示するか（3行以上で非表示にするか）どうかを判定
  judgeHideText() {
    if (this.isFlagResize) {
      return;
    }

    this.isFlagResize = true;

    //リサイズイベントの発生回数を減らす
    window.requestAnimationFrame(() => {
      //リサイズイベント
      let textInnerElem = document.querySelectorAll('.eventBox_text_inner');

      if (textInnerElem) {
        textInnerElem.forEach(elem => {
          if (elem instanceof HTMLElement) {
            let innerHeight = elem.offsetHeight; //テキストの中身の高さ
            let outerElem = elem.parentNode;
            let outerHeight =
              outerElem instanceof HTMLElement ? outerElem.offsetHeight : 0; //テキストをラップする要素の高さ
            let index = String(elem.getAttribute('data-id'));

            //テキストの中身の方が大きいか判定
            if (innerHeight > outerHeight) {
              this.$set(this.isShowMoreView, index, true);
            } else {
              this.$set(this.isShowMoreView, index, false);
            }
          }
        });
      }
      this.isFlagResize = false;
    });
  }

  //リサイズイベント追加
  mounted() {
    window.addEventListener('resize', this.judgeHideText);
  }

  //dom構築後、描画が完了したタイミングで実施
  updated() {
    this.judgeHideText();
  }

  destroyed() {
    window.removeEventListener('resize', this.judgeHideText);
  }

  get eventRepo() {
    return new EventRepository();
  }

  get eventCmsRepository() {
    return new EventCmsRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  get isLastPage() {
    return this.totalCount < this.LIMIT_NUM * this.pageNo + 1;
  }

  get isFirstPage() {
    return this.pageNo === this.INITIAL_PAGE_NO;
  }

  async created() {
    //未ログイン時はログイン画面に遷移する
    if (!this.$auth.isAuthenticated) {
      this.$auth.loginWithRedirect({
        appState: { targetUrl: '/events?tab=registered' }
      });
      return;
    }
    await this.updateList(this.INITIAL_PAGE_NO);
  }

  async updateList(pageNo: number) {
    // イベント申込履歴を取得
    await this.getEventEntryList();
    // エラーの場合は終了
    if (this.errorMsg) {
      return;
    }
    // 申込履歴が0件の場合
    if (this.eventEntryItemList.length === 0) {
      this.infoMsg = this.$msg.get('2000103');
      return;
    }
    // ページング処理のため分割する
    this.splitListForPaging();
    this.getPageInfo(pageNo);
  }

  @Watch('pageNo')
  onChangePageNo() {
    this.getPageInfo(this.pageNo);
    // イベント概要の「もっと見る」ボタンを閉じる状態にする
    this.eventRegisteredItemList.forEach(item => {
      this.hiddenMoreText(String(item.index));
    });
  }

  // ページごとに分割する
  splitListForPaging() {
    this.splitPageList = this.divideArrList(
      this.eventEntryItemList,
      this.LIMIT_NUM
    );
  }

  // イベント申込履歴を取得する
  async getEventEntryList() {
    // APIからイベント申込履歴を取得する
    await this.eventRepo
      .getEventEntry()
      .then(res => {
        this.eventEntryItemList = res;
        this.totalCount = this.eventEntryItemList.length;
      })
      .catch((errCode: number) => {
        this.handleGetEventEntryErr(errCode);
      });
  }

  // イベント申込履歴画面表示用リストを作成する
  makeEventRegisteredItemList(
    eventEntryItemList: EventEntryItem[],
    eventCmsItemList: EventItem[]
  ) {
    // ユニークID
    let index = 1;
    this.eventRegisteredItemList = eventEntryItemList.map(item => {
      // イベントCMSリストからイベントIDが同じイベント情報を抽出する
      const eventCmsItem = eventCmsItemList.find(
        e => e.eventID === item.eventId
      );
      // イベントCMSリストのオプションからオプションIDが同じオプション情報を抽出する
      const eventOptionsItem = eventCmsItem?.eventOptions.find(
        e => e.eventOptionID === String(item.eventOptionId)
      );
      // イベント申込履歴画面用のオブジェクトに変換する
      const eventRegisteredItem: EventRegisteredItem = {
        index: index++,
        eventId: item.eventId, // イベントID
        eventEntryId: item.eventEntryId, // イベント申込ID
        title: eventCmsItem ? eventCmsItem.title : '', // イベントタイトル
        eventOptionDt: eventOptionsItem ? eventOptionsItem.eventOptionDt : '', // イベント開催日時
        eventOptionName: eventOptionsItem
          ? eventOptionsItem.eventOptionName
          : '', // イベント内選択肢名
        eventEntryDt: item.eventEntryDt, // イベント申込日時
        adultNum: item.adultNum, // 大人申込人数
        childNum: item.childNum, // 子供申込人数
        payAmount: item.payAmount, // 合計金額
        note: item.note, // 備考
        imgUrl: eventCmsItem ? eventCmsItem.imgUrl : '', // イメージ
        usedBtnText: eventCmsItem ? eventCmsItem.usedBtnText : '', // 消込ボタン文言
        usedStatus: item.usedStatus, // イベント消込ステータス
        usedDt: item.usedDt, // イベント消込日時
        isAuthCodeRequired: eventCmsItem
          ? eventCmsItem.isAuthCodeRequired
          : true, // 確認コード入力要否フラグ(TRUEの場合は入力必須)
        isUsed: item.isUsed, // イベント消込対象かつ使用済
        isNotUsed: item.isNotUsed // イベント消込対象かつ未使用
      };
      return eventRegisteredItem;
    });
  }

  private handleGetEventEntryErr(errCode: number) {
    switch (errCode) {
      case 50000:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      default:
        this.errorMsg = this.$msg.get('2000100');
        break;
    }
  }

  // 1ページの画面表示情報を取得する
  async getPageInfo(pageNo: number) {
    const onePageEventEntryItemList = this.splitPageList[pageNo - 1];
    if (onePageEventEntryItemList && onePageEventEntryItemList.length > 0) {
      // イベントIDリスト
      const eventIDList = onePageEventEntryItemList.map(e => e.eventId);
      // CMS情報を取得する
      const onePageCMSEventList = await this.eventCmsRepository.getList(
        eventIDList
      );
      this.makeEventRegisteredItemList(
        onePageEventEntryItemList,
        onePageCMSEventList
      );
    }
  }

  nextPage() {
    if (!this.isLastPage) {
      this.pageNo++;
    }
  }

  prevPage() {
    if (!this.isFirstPage) {
      this.pageNo--;
    }
  }

  // 配列を任意の個数の配列に分割する
  divideArrList(array: any[], number: number) {
    var arrList = [];
    var idx = 0;
    while (idx < array.length) {
      arrList.push(array.splice(idx, idx + number));
    }
    return arrList;
  }

  // 消込モーダルを表示
  openUsedConfirmDialog(e: EventRegisteredItem) {
    this.usedTargetEvent = e;
    this.isUsedConfirmDialogOpening = true;
  }

  // 消込完了モーダルを表示
  openUsedCompleteDialog() {
    this.isUsedCompleteDialogOpening = true;
  }

  // 消込実施
  usedEvent() {
    if (this.buttonLoading) {
      return;
    }
    this.errorMsgUsedConfirmDialog = '';
    this.buttonLoading = true;
    const body: ReqPatchUseEventStatus[] = [
      {
        event_entry_id: this.usedTargetEvent.eventEntryId
      }
    ];
    this.eventRepo
      .patchUseEventStatus(body)
      .then(() => {
        this.usedTargetEvent = {} as EventRegisteredItem;
        this.isUsedConfirmDialogOpening = false;
        this.isUsedCompleteDialogOpening = true;
        // 申込履歴の再取得
        this.updateList(this.pageNo);
      })
      .catch(() => {
        this.errorMsgUsedConfirmDialog = this.$msg.get('2000113', {
          usedBtnText: this.usedTargetEvent.usedBtnText
        });
      })
      .finally(() => {
        this.buttonLoading = false;
      });
  }
}
