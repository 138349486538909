import Vue from 'vue';
import * as api from '@/gen/api';
import { EventEntryItems } from '@/models/EventEntryItem';

export class EventRepository {
  private async eventApiClient() {
    const config = await Vue.prototype.$getConfigWithToken(Vue.prototype.$auth);
    return new api.EventApi(config);
  }

  private async eventApiClientNoAuth() {
    const config = await Vue.prototype.$getDefaultConfig();
    return new api.EventApi(config);
  }

  // イベント在庫取得API
  async getEvent(eventId: string) {
    const client = await this.eventApiClientNoAuth();
    try {
      const res = await client.getEvent(eventId);
      return res.data;
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }
  // イベント申込API
  async postEventEntry(body: api.ReqPostEventEntry) {
    const client = await this.eventApiClient();
    try {
      const res = await client.postEventEntry(body);
      return Promise.resolve(res);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  // イベント申込履歴取得 概要:申込済みのイベント一覧を取得する。
  async getEventEntry() {
    const client = await this.eventApiClient();
    try {
      return await client.getEventEntry().then(res => {
        return EventEntryItems.valueOf(res.data);
      });
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  // イベント消込API
  async patchUseEventStatus(body: api.ReqPatchUseEventStatus[]) {
    const client = await this.eventApiClient();
    try {
      const res = await client.patchUseEventStatus(body);
      return Promise.resolve(res);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }
}
