import { ResGetEventEntry } from '@/gen';
export class EventEntryItem {
  static readonly USED_STATUS_NOT_AVAILABLE = '00';
  static readonly USED_STATUS_NOT_USED = '01';
  static readonly USED_STATUS_USED = '02';

  private constructor(
    readonly eventId: string, // イベントID
    readonly eventOptionId: string, // イベント内選択肢ID
    readonly eventEntryId: number, // イベント申込履歴ID
    readonly eventEntryDt: string, // イベント申込日時
    readonly adultNum: number, // 大人申込人数
    readonly childNum: number | undefined, // 子供申込人数 0の時は子供の参加人数が0人 null(key自体がない)の場合はそもそもadult/childの申込区分け自体がないイベント
    readonly payAmount: number, // 合計金額
    readonly note: string, // 備考
    readonly usedStatus: string, // イベント消込ステータス (00:消込対象でない、01:未消込、02:消込済)
    readonly usedDt: string // イベント消込日時
  ) {}

  static valueOf(data: ResGetEventEntry) {
    return new EventEntryItem(
      data.event_id, // イベントID
      data.event_option_id, // イベント内選択肢ID
      data.event_entry_id, // イベント申込履歴ID
      data.event_entry_dt, // イベント申込日時
      data.adult_num, // 大人申込人数
      data.child_num, // 子供申込人数 0の時は子供の参加人数が0人 null(key自体がない)の場合はそもそもadult/childの申込区分け自体がないイベント
      data.pay_amount, // 合計金額
      data.note, // 備考
      data.used_status, // イベント消込ステータス (00:消込対象でない、01:未消込、02:消込済)
      data.used_dt // イベント消込日時
    );
  }

  isNotUsed(): boolean {
    return this.usedStatus === EventEntryItem.USED_STATUS_NOT_USED;
  }

  isUsed(): boolean {
    return this.usedStatus === EventEntryItem.USED_STATUS_USED;
  }
}

export class EventEntryItems {
  private constructor(readonly list: EventEntryItem[]) {}

  static valueOf(data: ResGetEventEntry[]) {
    return data.map(e => EventEntryItem.valueOf(e));
  }
}
