













































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { EventItem } from '@/models/EventItem';
import { EventCmsRepository } from '@/repositories/EventCmsRepository';

@Component
export default class EventList extends Vue {
  @Prop()
  pathName!: string;
  // インフォメッセージ
  infoMsg = '';
  readonly INITIAL_PAGE_NO = 1;
  readonly LIMIT_NUM = 10; // 1度に表示する件数
  eventItems: Array<EventItem> = [] as Array<EventItem>;
  totalCount: number = 0;
  pageNo: number = this.INITIAL_PAGE_NO;

  async getEventItemList() {
    const listItem = await this.eventCmsRepository.getPage(
      this.LIMIT_NUM,
      this.LIMIT_NUM * (this.pageNo - 1),
      this.pathName
    );
    if (listItem) {
      this.eventItems = listItem.items;
      this.totalCount = listItem.totalCount;
    } else {
      this.infoMsg = this.$msg.get('2000105');
    }
  }

  get eventCmsRepository() {
    return new EventCmsRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }
  @Watch('pageNo', { immediate: true })
  onChangePageNo() {
    this.getEventItemList();
  }

  @Watch('pathName')
  onChangePathName() {
    // タブ変更時にページNoを1に設定する
    this.pageNo = this.INITIAL_PAGE_NO;
    // 値を初期化
    this.eventItems = [] as Array<EventItem>;
    this.totalCount = 0;
    this.getEventItemList();
  }
  nextPage() {
    if (!this.isLastPage) {
      this.pageNo++;
    }
  }

  prevPage() {
    if (!this.isFirstPage) {
      this.pageNo--;
    }
  }

  get isLastPage() {
    return this.totalCount < this.LIMIT_NUM * this.pageNo + 1;
  }

  get isFirstPage() {
    return this.pageNo === this.INITIAL_PAGE_NO;
  }
}
