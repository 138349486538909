











































































































































import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';

@Component
export default class UsedConfirmDialog extends Vue {
  @Prop({ required: true })
  readonly value!: boolean;

  @Prop({ required: true })
  // 消込処理を実施中であるか
  readonly buttonLoading!: boolean;

  @Prop({ required: true })
  // イベント名
  readonly eventName!: string;

  @Prop({ required: true })
  // イベント内選択肢名
  readonly eventOptionName!: string;

  @Prop({ required: true })
  // イベント開催日時
  readonly eventOptionDt!: string;

  @Prop({ required: true })
  // イベント申込日時
  readonly eventEntryDt!: string;

  @Prop({ required: true })
  // イベント申込ID
  readonly eventEntryId!: number;

  @Prop({ required: true })
  // 申込数（大人）
  readonly adultNum!: number;

  @Prop({ required: true })
  // 申込数（子ども）
  readonly childNum!: number;

  @Prop({ required: true })
  // 消込ボタン文言
  readonly usedBtnText!: string;

  @Prop({ required: true })
  // 確認コード入力要否フラグ
  readonly isAuthCodeRequired!: boolean;

  // 消込APIから返却されたエラーメッセージ
  @PropSync('usedEventErrMsg', { type: String })
  errMsg!: string;

  // ユーザが入力する確認番号数字
  authCode = '';

  // 入力したauthCode, errMsgの値をリセットする
  clearInputs() {
    this.authCode = ''; // authCodeを空文字に設定
    this.errMsg = ''; // errMsgを空文字に設定
  }

  get wrapperDialog() {
    return this.value;
  }

  set wrapperDialog(flag: boolean) {
    this.clearInputs();
    this.$emit('input', flag);
  }

  // 消込実施
  usedEvent() {
    // 確認コード入力必須の場合のみ確認番号バリデーションを実施
    if (this.isAuthCodeRequired) {
      if (!this.validateInput()) {
        return;
      }
    }
    // 確認コード入力不要の場合、もしくは確認番号バリデーションを通過した場合のみ消込実施
    this.clearInputs();
    this.$emit('usedEvent');
  }

  // 確認番号バリデーション
  // 確認番号ルール: イベント開催日時(eventOptionDt)のmmdd + 1000
  // 例: イベント開催日時が2023年7月1日の場合、(0701 + 1000) = 1701
  validateInput(): boolean {
    this.errMsg = '';

    // 確認番号が入力されていない場合
    if (this.authCode === '') {
      this.errMsg = this.$msg.get('2000110');
      return false;
    }

    // イベント開催日時から月日を取得
    const mm = this.eventOptionDt.substring(5, 7);
    const dd = this.eventOptionDt.substring(8, 10);
    // 確認番号を計算
    const code = Number(mm + dd) + 1000;
    // ユーザが入力した確認番号が誤っている場合
    if (code !== Number(this.authCode)) {
      this.errMsg = this.$msg.get('2000111');
      return false;
    }

    return true;
  }
}
